import React from "react";
import GoHome from "./LocBtn";

function ThankYouError(){
    return(
        <div id="error">
            <h3>Sorry, something went wrong</h3><br/><br/>
            <GoHome/>
        </div>
    )
}

export default ThankYouError;