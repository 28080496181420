import React from 'react';

const Project = () => {
  return (
    <div id='projectC'>
        <div id="comingSoon" >
            <h1>🚧 Work in progress 🚧</h1>
        </div>
    </div>
  )
};

export default Project;
