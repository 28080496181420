import React from "react";
import GoHome from "./LocBtn";

function ThankYou(){
    return (
        <div id='thankyou'>
            <h3>Sent! Thank you!</h3> 
            <br/><br/>
            <GoHome/>
        </div>
    )
}

export default ThankYou;